import { Helmet } from "react-helmet";

export default function home() {
  return (
    <div
      className="bg-gray-900 overflow-hidden font-face-gm"
      data-aos="fade-in"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blackbox Consultancy</title>
        {/* <meta name="description"></meta> */}
        <meta
          name="keywords"
          content="Marketing, Consultancy, Dublin, Ireland"
        ></meta>
        <meta
          property="image"
          content="https://images.unsplash.com/photo-1622737133809-d95047b9e673?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
        />
        {/* <meta
          name="og:description"
          content="Blackbox Consultancy is a Marketing Consultancy based in Dublin, Ireland"
        ></meta> */}
        <meta
          name="og:keywords"
          content="Marketing, Consultancy, Dublin, Ireland"
        ></meta>
        <meta
          property="og:image"
          content="https://images.unsplash.com/photo-1622737133809-d95047b9e673?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
        ></meta>
      </Helmet>
      <section className="relative backgroundImg pt-36 lg:pb-14 overflow-hidden bg-black h-screen">
        <img
          className="absolute object-cover top-0 left-1/2 transform -translate-x-1/2"
          alt=""
        />
        <div className="relative z-10 container mx-auto px-4 mb-12">
          <div className="flex flex-wrap -m-6">
            <div className="w-full lg:w-1/2 p-6">
              <div className="lg:max-w-xl" contenteditable="false">
                <h2
                  className="mb-6 font-heading font-bold text-6xl sm:text-7xl text-white"
                  contenteditable="false"
                >
                  Blackbox
                </h2>
                <h2
                  className="mb-6 font-heading font-bold text-6xl sm:text-7xl text-white"
                  contenteditable="false"
                >
                  Consultancy.
                </h2>
                <p className="text-white">info@blackboxcolsuntancy.ie</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
